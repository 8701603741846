import axios from 'axios';
const userInfo = JSON.parse(localStorage.getItem('token') as any);
export const instance = axios.create({
  // baseURL: 'https://centers-staging.rainn.org/api',
  baseURL:'https://centers.rainn.org/api',
  headers: {
    'content-type': 'application/json',
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token') as any);
    if (token) {
      config.headers.authorization = `Bearer ${token?.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default {
  center_list: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    sortColumn: string,
    centerName: any,
    categoryIds: string[],
    zip: any,
    state: string,
    startLastUpdatedDate: string,
    endLastUpdatedDate: string,
    exclude: boolean
  ) =>
    instance({
      method: 'GET',
      url: '/center/get-local-center-list',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        sortColumn,
        centerName,
        categoryIds: [categoryIds].join(','),
        zip,
        state,
        startLastUpdatedDate,
        endLastUpdatedDate,
        exclude,
      },
    }),
  export_center_list: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    centerName: any,
    categoryIds: string[],
    zip: any,
    state: string,
    startLastUpdatedDate: string,
    endLastUpdatedDate: string,
    exclude: boolean
  ) =>
    instance({
      method: 'GET',
      url: '/center/export-local-center',
      responseType: 'blob',

      params: {
        pageNumber,
        pageSize,
        sortOrder,
        centerName,
        categoryIds: [categoryIds].join(','),
        zip,
        state,
        startLastUpdatedDate,
        endLastUpdatedDate,
        exclude,
      },
    }),
  center_list_update_reminder: (id: string[]) =>
    instance({
      method: 'POST',
      url: `/center/send-profile-update-reminder`,
      data: id,
    }),
  center_list_view: (id: number) =>
    instance({
      method: 'GET',
      url: `center/view/local-center/${id}`,
    }),
  addCenter: (fd: any) =>
    instance({
      method: 'POST',
      url: 'center/create-local-center',
      data: fd,
    }),
  EditCenter: (fd: any, id: string) =>
    instance({
      method: 'PUT',
      url: `/center/update-local-center/${id}`,
      data: fd,
    }),
  center_Activate: (id: any) =>
    instance({
      method: 'POST',
      url: '/center/activate',
      params: {
        id,
      },
    }),
  center_Deactivate: (id: any) =>
    instance({
      method: 'POST',
      url: '/center/deactivate',
      params: {
        id,
      },
    }),
  Approve_requestlist: (
    page: any,
    pageRange: any,
    sortOrder: any,
    sortColumn: any
  ) =>
    instance({
      method: 'GET',
      url: '/notification/get-request-notification-list',
      params: {
        'page-number': page,
        'page-size': pageRange,
        sortOrder,
        sortColumn,
      },
    }),
  View_request: (id: number) =>
    instance({
      method: 'GET',
      url: `/notification/view-request-notification/${id}`,
    }),
  Approve_change_request: (
    remark: string,
    id: number,
    center_type_id: string
  ) =>
    instance({
      method: 'PUT',
      url: `notification/approve-center-change-request/${id}`,
    }),
  Approve_new_center_request: (
    remark: string,
    id: number,
    center_type_id: string
  ) =>
    instance({
      method: 'PUT',
      url: `notification/approve-new-center-request/${id}`,
      data: {
        remark,
        center_type_id,
      },
    }),
  Decline_change_request: (
    remark: string,
    id: number,
    center_type_id: string
  ) =>
    instance({
      method: 'PUT',
      url: `notification/reject-center-change-request/${id}`,
    }),
  Decline_new_center_request: (
    remark: string,
    id: number,
    center_type_id: string
  ) =>
    instance({
      method: 'PUT',
      url: `notification/reject-new-center-request/${id}`,
      data: {
        remark,
      },
    }),
  addCategory: (en: string, es: string) =>
    instance({
      method: 'POST',
      url: '/category/create',
      data: {
        name: {
          en: en,
          es: es,
        },
      },
    }),
  updateCategory: (id: number, en: string, es: string) =>
    instance({
      method: 'PUT',
      url: `/category/update/${id}`,
      data: {
        name: {
          en: en,
          es: es,
        },
      },
    }),
  deleteCategoryList: (id: number) =>
    instance({
      method: 'DELETE',
      url: `/category/delete/${id}`,
    }),
  viewCategory: (id: number) =>
    instance({
      method: 'POST',
      url: `/category/view/${id}`,
    }),
  addSubcategory: (subcategory: any) =>
    instance({
      method: 'POST',
      url: '/subcategory/create',
      data: subcategory,
    }),
  updateSubCategory: (id: number, en: string, es: string) =>
    instance({
      method: 'PUT',
      url: `/subcategory/update/${id}`,
      data: {
        name: {
          en: en,
          es: es,
        },
      },
    }),
  deleteSubCategoryList: (id: number) =>
    instance({
      method: 'DELETE',
      url: `/subcategory/delete/${id}`,
    }),
  viewSubCategory: (id: number) =>
    instance({
      method: 'POST',
      url: `/subcategory/view/${id}`,
    }),
  getCategoryList: () =>
    instance({
      method: 'GET',
      url: '/category/get-category-list',
    }),
  getNationalCenterList: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    centerName: any,
    categoryIds: string[],
    sortColumn: any
  ) =>
    instance({
      method: 'GET',
      url: '/center/get-national-center-list',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        centerName,
        categoryIds: [categoryIds].join(','),
        sortColumn,
      },
    }),
  NationalCenterAdd: (data: any) =>
    instance({
      method: 'POST',
      url: '/center/create-national-center',
      data: data,
    }),
  NationalCenterEdit: (data: any, id: any) =>
    instance({
      method: 'PUT',
      url: `/center/update-national-center/${id}`,
      data: data,
    }),
  NationalCenterView: (id: number) =>
    instance({
      method: 'GET',
      url: `center/view/national-center/${id}`,
    }),
  changePassword: (current_Password: string, new_Password: string) =>
    instance({
      method: 'POST',
      url: '/user/update-password',
      data: {
        newPassword: new_Password,
        oldpassword: current_Password,
      },
    }),
  GetInformation: () =>
    instance({
      method: 'GET',
      url: '/user/get-myinfo',
    }),

  NoUpdate_Information: (
    categories: any,
    center: any,
    contact: any,
    volunteer_contact: any,
    token: any
  ) =>
    instance({
      method: 'POST',
      url: `/center/unsubscribe/profile-update-reminder/`,
      data: {
      collaboration_with_the_state_coalition:{categories_value: categories},
        center: center,
        user: contact,
      },
    }),
  EditInformation: (
    fd: any,
    categories: any,
    center: any,
    contact: any,
    volunteer_contact: any
  ) =>
    instance({
      method: 'POST',
      url: '/user/update-myinfo',
      data: {
        fd,
        data: {
          categories_value: categories,
          center: center,
          user: contact,
         },
      },
    }),
  EmailTemplatelist: () =>
    instance({
      method: 'GET',
      url: `email-template/get-list`,
    }),
  ViewEmailTemplatelist: (id: number) =>
    instance({
      method: 'GET',
      url: `/email-template/view/${id}`,
    }),
  EditEmailTemplatelist: (id: number, subject: string, content: string) =>
    instance({
      method: 'PUT',
      url: `/email-template/update/${id}`,
      data: {
        subject,
        content,
      },
    }),
  denied_center_list: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    sortColumn: string,
    centerName: any,
    categoryIds: string[],
    zip: any,
    state: string,
    startRejectedDate: string,
    endRejectedDate: string
  ) =>
    instance({
      method: 'GET',
      url: '/center/get-denied-center-list',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        sortColumn,
        centerName,
        categoryIds: [categoryIds].join(','),
        zip,
        state,
        startRejectedDate,
        endRejectedDate,
      },
    }),
  Export_denied_center_list: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    centerName: any,
    categoryIds: string[],
    zip: any,
    state: string,
    startRejectedDate: string,
    endRejectedDate: string
  ) =>
    instance({
      method: 'GET',
      url: '/center/export-denied-center',
      responseType: 'blob',

      params: {
        pageNumber,
        pageSize,
        sortOrder,
        centerName,
        categoryIds: [categoryIds].join(','),
        zip,
        state,
        startRejectedDate,
        endRejectedDate,
      },
    }),
  Message_to_rainn: (message: any) =>
    instance({
      method: 'POST',
      url: 'center/send-message-to-rainn',
      data: {
        message: message,
      },
    }),
  Volunteer_Request_list: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    sortColumn: string,
    zip: any,
    state: string,
    createdAt: any,
    volunteerOpportunityId: any
  ) =>
    instance({
      method: 'GET',
      url: '/volunteer/request/get-list',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        sortColumn,
        zip,
        state,
        createdAt,
        opportunityId: [volunteerOpportunityId].join(','),
      },
    }),
  Volunteer_request_view: (id: number) =>
    instance({
      method: 'GET',
      url: `/volunteer/request/view/${id}`,
    }),
  Volunteer_need_add: (
    title: string,
    description: string,
    start_date: any,
    end_date: any,
    ids: any
  ) =>
    instance({
      method: 'POST',
      url: '/volunteer/opportunity/save',
      data: {
        description,
        end_date,
        option_id: ids,
        start_date,
        title,
      },
    }),
  Volunteer_need_view: (id: number) =>
    instance({
      method: 'GET',
      url: `/volunteer/opportunity/view/${id}`,
    }),

  Opportunity_form_data: () =>
    instance({
      method: 'GET',
      url: `/volunteer/opportunity/form-data/get-list`,
    }),
  Volunteer_need_edit: (
    title: string,
    description: string,
    start_date: any,
    end_date: any,
    id: number,
    ids: any
  ) =>
    instance({
      method: 'PUT',
      url: `/volunteer/opportunity/update/${id}`,
      data: {
        description,
        end_date,
        option_id: ids,
        start_date,
        title,
      },
    }),
  Opportunity_List: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    sortColumn: string
  ) =>
    instance({
      method: 'GET',
      url: '/volunteer/opportunity/get-list',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        sortColumn,
      },
    }),
  Volunteer_Opportunity_List: (
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    sortColumn: string
  ) =>
    instance({
      method: 'GET',
      url: '/volunteer/request/get-list-by-center',
      params: {
        pageNumber,
        pageSize,
        sortOrder,
        sortColumn,
      },
    }),
  Opportunity_delete: (id: number) =>
    instance({
      method: 'DELETE',
      url: `/volunteer/opportunity/delete/${id}`,
    }),
  Center_change: (id: any) =>
    instance({
      method: 'GET',
      url: `/user/switch-center/${id}`,
    }),
  Request_call_data: (info: string) =>
    instance({
      method: 'POST',
      url: `/center/request-call-data`,
      data: { month: info },
    }),
};
